@use 'css/scss/typography';

.text {
  color: var(--color-dominant-on-dominant);
  margin: initial;
  max-width: 100%;
  white-space: pre-wrap;

  &.labelDefault {
    @include typography.label-default;
  }

  &.labelLarge {
    @include typography.label-large;
  }

  &.titlePageTitle {
    @include typography.title-page-title;
  }

  &.titleSubtitle {
    @include typography.title-subtitle;
  }

  &.titleModalTitle {
    @include typography.title-modal-title;
  }

  &.titleSidePanelTitle {
    @include typography.title-sidepanel-title;
  }

  &.titleSectionTitleS {
    @include typography.title-section-title-S;
  }

  &.titleSectionTitleM {
    @include typography.title-section-title-M;
  }

  &.titleTileTitle {
    @include typography.title-tile-title;
  }

  &.titleTitleBigBold {
    @include typography.title-big-title;
  }

  &.titleTileSubtitle {
    @include typography.title-tile-subtitle;
  }

  &.textBodySmall {
    @include typography.text-body-small;
  }

  &.textBodySmallBold {
    @include typography.text-body-small-bold;
  }

  &.textBodyMedium {
    @include typography.text-body-medium;
  }

  &.textBodyMediumBold {
    @include typography.text-body-medium-bold;
  }

  &.textBodyLarge {
    @include typography.text-body-large;
  }

  &.textBodyLargeBold {
    @include typography.text-body-large-bold;
  }

  &.kolIdentity {
    @include typography.kol-identity;
  }

  &.kolSmallIdentity {
    @include typography.kol-small-identity;
  }

  &.kolUsername {
    @include typography.kol-username;
  }

  &.kolSmallUsername {
    @include typography.kol-small-username;
  }
}