div.container {
  display: grid;

  grid-auto-flow: dense;
  align-items: flex-end;

  grid-template-columns: auto 1fr;
  /* Two equal width columns */
  grid-template-rows: repeat(2, auto);
  /* Two rows with auto height */
  gap: 8px;

  .countryLabel {
    /* Default: span across both columns */
    grid-column: 1 / span 2;

    &:has(~.numberLabel) {
      /* If .second exists, .first will occupy only the first column */
      grid-column: 1 / 2;
    }
  }

  .numberLabel {
    /* Occupy the second column */
    grid-column: 2 / 3;
  }

  .countrySelector {
    /* Occupy the first column on the second row */
    grid-column: 1 / 2;
  }

  .numberInput {
    /* Occupy the second column on the second row */
    grid-column: 2 / 3;

    >div>input[type="tel"] {
      padding-right: var(--spacing-spacing-x-small);
    }

    span[role="button"] {
      // display: none;
      right: 8px;
    }

    &:hover span[role="button"] {
      display: flex;
    }
  }
}