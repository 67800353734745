@use 'token';
@use 'kolkit/styles/mixins';


.no-overflow-y {
  &>div {
    overflow-y: visible !important;
    &>div {
      overflow-y: visible !important;
    }
  }
}

.zindex2 {
  z-index: 2;
}

.zindex3 {
  z-index: 3;
}

.zindex4 {
  z-index: 4;
}

.zindex5 {
  z-index: 5;
}

.prel {
  position: relative !important;
}

.tac {
  text-align: center !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.flexRow {
  display: flex;
  flex-flow: row wrap;
}

.flex {
  display: flex !important;
}

.flex {
  &.aifs {
    align-items: flex-start;
  }
  &.aife {
    align-items: flex-end;
  }
  &.aic {
    align-items: center;
  }
  &.aib {
    align-items: baseline;
  }
  &.ais {
    align-items: stretch;
  }

  &.jcc {
    justify-content: center;
  }
  &.jcs {
    justify-content: flex-start;
  }
  &.jce {
    justify-content: flex-end;
  }
  &.jcc {
    justify-content: center;
  }
  &.jcsb {
    justify-content: space-between;
  }
  &.jcsa {
    justify-content: space-around;
  }
  &.jcse {
    justify-content: space-evenly;
  }
  &.jcst {
    justify-content: stretch;
  }

  &.fdc {
    flex-direction: column;
  }
  &.fdcr {
    flex-direction: column-reverse;
  }
  &.fdr {
    flex-direction: row;
  }
  &.fdrr {
    flex-direction: row-reverse;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.f1 {
    flex: 1;
  }
  &.f2 {
    flex: 2
  }
}

.asc {
  align-self: center;
}
.ass {
  align-self: stretch;
}
.asfs {
  align-self: flex-start;
}
.asfe {
  align-self: flex-end;
}

.grid {
  display: grid;
}

.tac {
  text-align: center;
}

.tas {
  text-align: start;
}

.tae {
  text-align: end;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.main-content {
  display: flex;
  flex-flow: column;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 100%; // avoid horizontal scroll
  padding: 0;
  background: var(--color-tertiary-025);
  overflow-y: auto;
  z-index: 0;

  &.unset-scroll {
    overflow-y: unset;
  }

  &.grey-bg {
    background-color: var(--color-navy-025);
  }

  &.no-header {
    padding-top: 0;
  }
}

// Main container
#root>div.loaded {
  display: flex;
  align-items: stretch;
  height: 100vh;

  >.main_container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 64px);
  }
}

.structure_scroll {
  position: relative;
  height: 100vh;
  flex: 1;
  overflow: hidden;
  &.full-width {
    width: 100vw;
    margin-left: 0;
  }

  .scroll_content {
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
}

.one-fixed-column {
  position: relative;
  display: block;
  width: 1032px;
  margin-left: auto;
  margin-right: auto;
}

.main-wrapper {
  font-size: 0;
  transform: translateX(0%);
  transition: transform 0.3s ease-out;
}

.main-wrapper > div {
  display: inline-block;
  vertical-align: top;
}

/* GLOBAL APP LOADER */
.global-loader {
  display: grid;
  width: 100%;
  height: 100vh;
  font-size: 0;
  place-items: center;

  .logo {
    width: 70%;
    max-width: 320px;
    height: auto;
  }

  .loader svg {
    width: 100px;
    height: 100px;
  }

  > div.loader {
    text-align: center;
    max-width: 700px;
  }

  .global-loader_loader {
    display: flex;
    justify-content: center;
  }
}
/****************/

/***Chart ***/

#chartjs-tooltip, .chartjs-tooltip {
  padding: 4px;
  color: var(--color-dominant-on-dominant);
  background-color: var(--color-dominant-white);
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 48, 87, 0.2);
  z-index: 2147483647;
  transition: all 400ms ease;
}

.chart-growth {
  width: 356px;
  padding: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-font-size-3);
    border-bottom: var(--border-thin);
    padding-bottom: var(--spacing-spacing-thin);
    margin-bottom: var(--spacing-spacing-thin);

    .bold {
      font-weight: var(--font-weight-bold);
    }

    .rate, .avg-followers {
      font-size: var(--font-size-font-size-3);
      font-weight: var(--font-weight-regular);
    }

    .rate {
      color: var(--color-tertiary-300);
    }

    .flex-aic-full {
      display: flex;
      align-items: center;
      justify-self: stretch;
      margin-top: 4px;
    }
    .legend {
      width: 8px;
      height: 21px;
      border-radius: 4px;
      margin-right: 8px;
      background: var(--color-tertiary-100);

      &.total {
        background: var(--color-instagram-gradient);
      }

      &.evolution {
        background: var(--color-evolution-up);

        &.negative {
          background: var(--color-evolution-down);
        }
      }
    }

    span {
      display: block;
    }
  }
  table {
    width: 100%;
    th, td {
      font-size: var(--font-size-font-size-3);
      padding: 4px;
      text-align: right;
      &:first-child {
        text-align: left;
      }
      .with-bullet {
        display: flex;
        align-items: center;
        .bullet {
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
          &.organic {
            background-color: #0061AC;
          }
          &.owned {
            background-color: #74DBFD;
          }
          &.campaign {
            background-color: #00A6F4;
          }
        }
      }
    }
  }
}
.chart-growth-profile {
  width: 250px;
  padding: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-font-size-3);
    padding-bottom: var(--spacing-spacing-thin);
    margin-bottom: var(--spacing-spacing-thin);

    .bold {
      font-weight: var(--font-weight-bold);
    }

    .rate, .avg-followers {
      font-size: var(--font-size-font-size-3);
      font-weight: var(--font-weight-regular);
    }

    .rate {
      color: var(--color-tertiary-300);
    }

    .flex-aic-full {
      display: flex;
      align-items: center;
      justify-self: stretch;
      margin-top: 4px;
    }
    .legend {
      width: 8px;
      height: 21px;
      border-radius: 4px;
      margin-right: 8px;
      background: var(--color-tertiary-100);

      &.total {
        background: var(--color-instagram-gradient);
      }

      &.evolution {
        background: var(--color-evolution-up);

        &.negative {
          background: var(--color-evolution-down);
        }
      }

      &.medias {
        background: #74DBFD;
      }
      &.engagement {
        background: #C0D3D7;
      }
    }

    span {
      display: block;
    }
  }
}

.chart_tooltipDot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 100%
}

.chart_tooltipKeyValue {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-dominant-on-dominant);
  @include mixins.chart_legend;

  &:global(.active) {
    font-weight: 700;
  }
}

.chart_tooltipKey {
  text-align: left;
  margin: 0 var(--spacing-spacing-xx-small) 0 -4px;
}

.chart_tooltip_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 2px;
  font-weight: bold;
  font-size: var(--font-size-font-size-3);
  line-height: 150%;
}

.tbody_tooltip {
  display: block;
  &.doughnut-tooltip {
    font-size: var(--font-size-font-size-3);

    .chart_tooltipDot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      border-radius: 100%
    }
    .chart_tooltipPercent {
      text-align: right;
      padding-right: var(--spacing-spacing-xx-small);
    }
  }
}

.chart_tooltipValue {
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

.chart_legend_container {
  position: absolute;
  display: flex;
  width: 100%;
}

// KOL Distribution chart
.kolDistribution {
  border-spacing: 8px 0;
  
  .chart_tooltipDot {
    margin-right: 0;
  }
  
  tr > td:not(:first-child),
  tr > th:not(:first-child) {
    padding-left: 8px;
    text-align: left;
  }
}

.menu-drawer-content {
  width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  a, button.link {
    border: none;
    background: none;
    margin: 0;
    padding: 20px var(--spacing-spacing-xx-small);
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #F2F2F2;;
    color: var(--color-dominant-on-dominant);
    font-family: var(--font-family);
    text-align: left;
    font-size: 14px;
    text-decoration: none;

    svg {
      fill: var(--color-dominant-on-dominant);
      width: 13px;
      height: auto;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }
  .locales-switcher {
    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ul {
      padding-left: 30px;
    }
    li {
      flex-grow: 0;
      button {
        padding-left: 10px !important;
        padding-right: 10px;
      }
    }
    .switcher-item {
      border: none;
    }
  }
}

/* Utils */
.hidden {
  display: none !important;
}

/* BUTTONS */
.no-btn {
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  text-decoration: inherit;
  text-align: inherit;
  text-transform: inherit;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.no-btn:focus, .no-btn:active {
  outline: none;
}
.btn {
  padding: 15px;
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.2s linear;
  display: block;
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: var(--color-dominant-on-dominant);
  background-color: var(--color-dominant-white);
  border: 1px solid var(--color-dominant-white);
}

.btn-round-ico {
  width: 40px;
  height: 40px;
  background-color: var(--color-dominant-white);
  border-radius: 50%;
  svg {
    position: relative;
    display: block;
    width: 25px;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    path {
      fill: var(--color-dominant-on-dominant);
    }
  }
}
.btn svg path {
  transition: fill 0.2s linear;
}
.btn.squared {
  border-radius: 0;
}
.btn-ico-label {
  padding: 5px 10px 5px 5px;
}
.btn-ico {
  padding: 5px;
}
.btn-ico > *, .btn-ico-label > * {
  display: inline-block;
  vertical-align: middle;
}
.btn-ico svg, .btn-ico-label svg {
  width: 30px;
  height: auto;
}
.btn-list {
  width: 100%;
  text-align: left;
}

.no-action {
  pointer-events: none;
  cursor: default;
}

.pe-a {
  pointer-events: all;
}

.pad10 {
  padding: 10px;
}

/* State Status */
.profile-project-label, .state-project-label {
  padding: 3px 5px;
  color: var(--color-dominant-white);
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
}

/* LOADER */
.main-loader {
  width: initial !important;
  top: -8px !important;
  left: -8px !important;
  right: -8px !important;
}

.section-loader {
  padding-top: 20px;
}

.drawer-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Badge */
span.badge {
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;;
  text-transform: uppercase;
  display: inline-block;
  background-color: var(--color-dominant-white);
  color: var(--color-dominant-on-dominant);
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  position: relative;
  svg {
    display: inline-block;
    position: relative;
    width: 15px;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
    path {
      fill: var(--color-dominant-on-dominant);
    }
  }
  &.badge-green {
    background-color: var(--color-primary-700);
    color: var(--color-dominant-white);
    svg path {
      fill: var(--color-dominant-white);
    }
  }

  &.badge-big {
    width: 40px;
    height: 40px;
    line-height: 40px;
    svg  {
      width: 30px;
    }
  }

  &.badge-mega {
    width: 100px;
    height: 100px;
    line-height: 100px;
    svg  {
      width: 70px;
    }
  }

  &.badge-float-top-center {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
}

/* Helpers */
label svg {
  width: 30px;
  height: auto;
}
.pad8 {
  padding: 8px;
}
.pad16 {
  padding: 16px;
}
.pad20 {
  padding: 20px;
}
.pad24 {
  padding: 24px;
}
.pad30 {
  padding: 30px;
}
.pad40 {
  padding: 40px;
}
.w100 {
  width: 100px;
}
.w100p {
  width: 100% !important;
}
.w150 {
  width: 150px;
}
.w200 {
  width: 200px;
}
.w300 {
  width: 300px;
}
.w400 {
  width: 400px;
}
.w500 {
  width: 400px;
}
.h100p {
  height: 100%;
}
.ml5 {
  margin-left: 5px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml20 {
  margin-left: 20px;
}
.ml24 {
  margin-left: 24px;
}
.ml32 {
  margin-left: 32px;
}
.mr5 {
  margin-right: 5px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr15 {
  margin-right: 15px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
.mr32 {
  margin-right: 32px;
}
.mr40 {
  margin-right: 40px;
}
.mr48 {
  margin-right: 48px;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mt48 {
  margin-top: 48px;
}
.mt56 {
  margin-top: 56px;
}
.mt64 {
  margin-top: 64px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb80 {
  margin-top: 80px;
}
.padb20 {
  padding-bottom: 20px;
}
.fs0 {
  flex-shrink: 0;
}
.fs1 {
  flex-shrink: 1;
}
.fb0 {
  flex-basis: 0;
}
.fg1 {
  flex-grow: 1;
}
.fg2 {
  flex-grow: 2;
}
.fg3 {
  flex-grow: 3;
}
.fg4 {
  flex-grow: 4;
}
.nowrap {
  white-space: nowrap;
}
.block {
  display: block;
}
.gap4 {
  gap: 4px;
}
.gap8 {
  gap: 8px;
}
.gap16 {
  gap: 16px;
}
.gap24 {
  gap: 24px;
}
.gap32 {
  gap: 32px;
}

.dns {
  display: none;
}

.text-initial {
  text-transform: initial !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.minWidth0 {
  min-width: 0;
}

.overflowAuto {
  overflow: auto;
}

.userSelectNone {
  user-select: none;
}

.pre {
  white-space: pre;
}

.pre-wrap {
  white-space: pre-wrap;
}

/* Bottom right sticky */
.bottom-right-sticky {
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 20;

  a {
    width: 55px;
    height: 55px;
    background-color: var(--color-pink-300);
    border-radius: 50%;
    display: block;
    position: relative;
    transition: background-color 0.2s linear, box-shadow 0.2s linear;
    &:hover {
      background-color: #e56b97;
    }

    svg {
      width: 21px;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: var(--color-dominant-white);
    }
  }
}

/* resize checker */
#resize-size-checker {
  position: absolute;
  width: 5px;
  height: 0;
  display: block;
  pointer-events: none;
}

/* TAG BETA */
.tag-beta {
  padding: 3px 5px;
  border-radius: 2px;
  margin-left: 10px;
  position: relative;
  border: 1px solid var(--color-pink-300);
  color: var(--color-pink-300);
  font-weight: bold;;
  font-size: 8px;
  top: -5px;
}

/* WARNING */
.warning-no-internet {
  position: fixed;
  bottom: 0;
  left: 10px;
  padding: 15px;
  background-color: #c41c00;
  color: #fbe9e7;
  font-size: 14px;
  font-weight: 300;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: relative;
  }
  span {
    margin-left: 7px;
  }
}

/* HINTS */
.hint {
  background-color: rgba(0,0,0,0.90);
  font-size: 14px;
  padding: 10px 15px;
  font-family: var(--font-family);
  border-radius: 5px;
  white-space: nowrap;

  color: var(--color-dominant-white);
  box-shadow: 10px 0 30px 0 rgba(0,0,0,0.5);
  pointer-events: none;
  h5 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
  p {
    margin: 5px 0;
    &.hint-avg-commitment {
      white-space: normal;
      width: 310px
    }
  }
}

/* TOASTS */
.Toastify__toast-container {
  z-index: token.z(toaster) !important;
  &.Toastify__toast-container--bottom-center {
    width: 650px;
  }
  &.Toastify__toast-container--top-right {
    width: auto;
  }
}

.toast {
  width: 100%;
  color: var(--color-dominant-white);
  font-family: var(--font-family);
  font-size: 14px;
  min-height: 48px;
  font-weight: 400;

  span {
    font-weight: bold;
    white-space: nowrap;
  }
  button {
    color: #798B97;
    svg {
      font-size: 16px;
    }
  }

  &.Toastify__toast {
    padding: var(--spacing-spacing-xx-small);
    padding-left: 20px;
    border-radius: var(--border-radius-small);
    background-color: var(--color-dominant-on-dominant);
    align-items: flex-start;

    max-width: 580px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      content: "";
      width: var(--size-sizing-thin);
    }

    &--info::before {
        background-color: var(--color-alert-information);
    }

    &--success::before {
      background-color: var(--color-alert-success);
    }

    &--warning::before {
      background-color: var(--color-alert-warning);
    }

    &--error::before {
      background-color: var(--color-alert-error);
    }

    &--new::before {
      background-color: var(--color-alert-new);
    }

    .Toastify__toast-body {
      padding: 0;
      align-items: flex-start;
    }

    .Toastify__toast-icon {
      margin-inline-end: var(--spacing-spacing-xx-small);
      width: var(--size-sizing-x-small);
    }

    .Toastify-title {
      font-weight: 600;
      font-size: var(--font-size-font-size-5);
      color: var(--color-dominant-white);
      line-height: 20px;
      margin-bottom: 4px;
    }

    .Toastify-message {
      font-weight: 400;
      font-size: var(--font-size-font-size-4);
      color: var(--color-dominant-white);
      white-space: pre-wrap;
    }

    >button {
      margin-top: -12px;
      margin-right: -12px;
      color: var(--color-dominant-white);
      stroke: var(--color-dominant-white);
      stroke-width: 1px;
    }

    a {
      color: var(--dominant-white, #FFF);
      text-decoration: underline;
      font-size: var(--font-size-font-size-4);
      font-weight: 400;
      line-height: var(--line-height-text);
    }
  }
}

.message-new-version-available--container {
  position: fixed;
  bottom: -200px;
  right: 20px;
  transition: bottom 0.2s ease-out;
  will-change: bottom;
  z-index: 9999;
  pointer-events: none;
  .message-new-version-available {
    padding: 24px;
    background-color: var(--color-dominant-on-dominant);
    color: var(--color-dominant-white);
    width: 360px;
    border-radius: 4px;

    p {
      margin: 0 0 20px 0;
    }
  }
  &.--show {
    bottom: 20px;
    pointer-events: all;
  }
}

/* Tablet landscape */
@media screen and (max-width: 1199px) {
  .one-fixed-column {
    width: 1024px;
  }
  #resize-size-checker {
    width: 4px;
  }
  .btn {
    font-size: 14px;
  }
  .bottom-right-sticky {
    right: 20px;
    bottom: 20px;
  }
  header {
    .header-nav-links {
      width: auto;
      padding-left: 0;
      left: 40%;
      transform: translateX(-50%);

      a {
        margin-right: 35px;
      }
    }
  }
}

/*  Tablet Portrait */
@media screen and (max-width: 768px) {
  .one-fixed-column {
    width: 100vw;
    left: 0;
  }
  #resize-size-checker {
    width: 3px;
  }
  header {
    .header-nav-links {
      width: auto;
      padding-left: 0;
      a {
        margin-right: 30px;
      }
    }
  }
}

/* Mobile */
@media screen and (max-width: 640px) {
  h5 {
    font-size: 20px;
  }
  #resize-size-checker {
    width: 2px;
  }
  .bottom-right-sticky {
    right: 10px;
    bottom: 10px;
  }

  span.badge.badge-mega {
    width: 70px;
    height: 70px;
    line-height: 70px;
    svg  {
      width: 50px;
    }
  }

  .warning-no-internet {
    left: 0;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  /* TOASTS */
  .Toastify__toast-container {
    width: 100%;
    &.Toastify__toast-container--bottom-center {
      margin-left: 0;
    }
  }

  .message-new-version-available--container {
    bottom: 0;
    right: 0;
    width: 100%;

    .message-new-version-available {
      padding: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.icon {
  &.icon--facebook svg {
    fill: #3E5699
  }
  &.icon--twitter svg {
    fill: #02AEE7;
  }
  &.icon--instagram svg {
    fill: #973588;
  }
  &.icon--youtube svg {
    fill: #D11314;
  }
}

/* react-dates */
.DateRangePicker_picker, .SingleDatePicker_picker {
  background-color: transparent;
  top: 42px!important;
  z-index: token.z(datePicker) !important;
}
.DayPicker__withBorder {
  border-radius: var(--border-radius-small);
  box-shadow: var(--menu-shadow);
}
.SingleDatePicker_picker__portal {
  top: 0 !important;
}


/* REACT SELECT */
.select_aes {
  &>div {
    position: relative;
    &>div:nth-child(3) {
      z-index: 5000 !important;
      position: absolute !important;
      top: initial !important;
      bottom: 30px !important;
    }
  }
}

.action-button--center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Select */
.bnc_field_input .bnc_field_input.bnc_field_input--small input {
  height: 32px !important;
  line-height: 32px !important;
}

/* Checkbox */
.bnc_field_checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: var(--color-dominant-on-dominant);
  font-weight: normal;
  font-size: 13px;
  &.--disabled {
    cursor: default;
    color: #CCD6E3;
    input {
      border-color: #CCD6E3;
    }
  }
  &.--checked {
    input {
      border-color: #00ccff;
      background-color: #00ccff;
      &:disabled {
        background-color: #CCD6E3;
      }
    }
  }
  .bnc_field_checkbox_input {
    display: flex;
    align-items: center;
    position: relative;
    input {
      margin: 0;
      padding: 0;
      appearance: none;
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid #b2c0cc;
      transition: border 0.2s linear, background-color 0.2s linear;
      border-radius: 4px;
      cursor: pointer;
      &:focus {
        outline: none;
        border-color: #00ccff;
      }
      &:checked {
        border-color: #00ccff;
        background-color: #00ccff;
        &:disabled {
          background-color: #CCD6E3;
        }
      }
      &:disabled {
        cursor: default;
      }
    }
    .bnc_field_checkbox_tick {
      height: 12px;
      width: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        width: 12px;
        height: auto;
        fill: var(--color-dominant-white);
      }
    }
  }
}

.inactive-section {
  filter: blur(7px);
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

/* Modales Maintenance */
.modale--maintenance {
  text-align: center;
  padding: 40px 65px 80px !important;
  .ico {
    display: block;
    margin-bottom: 30px;
    svg {
      width: 36px;
      height: 36px;
      fill: var(--color-dominant-on-dominant);
    }
  }
  h2 {
    padding: 0;
    margin: 0 0 30px 0;
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    color: var(--color-dominant-on-dominant);
  }
  p {
    margin: 0 0 30px 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-dominant-on-dominant);

    span {
      display: block;
      margin-top: 7px;
      opacity: 0.6;
      font-size: 14px;
      line-height: 21px;
      color: inherit;
    }
  }
}

.half-column {
	width: 50%;
	display: inline-block;
}

/* Mobile only */
@include mixins.breakpoint(phone) {
  .half-column, .tier-column, .two-tier-column {
  	width: 100%;
	display: block;
  }
}

// Override react-phone-number-input styles
.PhoneInputCountryIcon--border {
  --PhoneInputCountryFlag-backgroundColor--loading: var(--color-primary-050);
}
.PhoneInputCountryIconImg {
  background-color: var(--color-primary-050);
}