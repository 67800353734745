@mixin label-default {
    /* label/default */
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-font-size-3);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-text);
}

@mixin label-large {
    /* label/large */
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-font-size-4);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-text);
}

@mixin title-modal-title {
    /* title/modal-title */
    font-family: var(--font-family-var), sans-serif;
    font-size: var(--font-size-font-size-6);
    font-style: normal;
    font-weight: 617;
    line-height: var(--line-height-heading);
    letter-spacing: 0.48px;
}

@mixin title-tile-title {
    /* title/tile-title */
    color: var(--color-dominant-on-dominant);
    font-family: var(--font-family-var), sans-serif;
    font-size: var(--font-size-font-size-4);
    font-style: normal;
    font-weight: 469;
    line-height: var(--line-height-text);
    letter-spacing: 0.32px;
}

@mixin title-tile-subtitle {
    /* title/tile-subtitle */
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-font-size-3);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-text);
}

@mixin title-page-title {
    /* title/page-title */
    font-family: var(--font-family-var), sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 617;
    line-height: var(--line-height-text);
    letter-spacing: 0.44px;
    text-transform: uppercase;
}

@mixin title-big-title {
    /* title/page-title */
    font-family: var(--font-family-heading), sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: var(--line-height-text);
    letter-spacing: 0.44px;
    text-transform: uppercase;
}

@mixin title-subtitle {
    /* title/subtitle */
    font-family: Nunito, sans-serif;
    font-size: var(--font-size-font-size-4);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-text);
}

@mixin title-section-title-S {
    /* title/section-title-S */
    color: var(--color-dominant-on-dominant);
    font-family: var(--font-family-var), sans-serif;
    font-size: var(--font-size-font-size-4);
    font-style: normal;
    font-weight: 469;
    line-height: var(--line-height-text);
    letter-spacing: 0.32px;
}

@mixin title-section-title-M {
    /* title/section-title-M */
    color: var(--color-dominant-on-dominant);
    font-family: var(--font-family-var), sans-serif;
    font-size: var(--font-size-font-size-5);
    font-style: normal;
    font-weight: 617;
    line-height: var(--line-height-text);
    letter-spacing: 0.36px;
}

@mixin title-sidepanel-title {
    /* title/sidepanel-title */
    font-family: var(--font-family-var), sans-serif;
    font-size: var(--font-size-font-size-6);
    font-style: normal;
    font-weight: 617;
    line-height: var(--line-height-heading);
    letter-spacing: 0.48px;
}

@mixin text-body-small {
    /* text/body small */
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-font-size-1);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-text);
}

@mixin text-body-small-bold {
    /* text/body small bold */
    @include text-body-small;
    font-weight: 700;
}

@mixin text-body-medium {
    /* text/body medium */
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-font-size-3);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-text);
}

@mixin text-body-medium-bold {
    /* text/body medium bold */
    @include text-body-medium;
    font-weight: 700;
}

@mixin text-body-large {
    /* text/body large */
    @include text-body-small;
    font-size: var(--font-size-font-size-4);
}

@mixin text-body-large-bold {
    /* text/body large bold */
    @include text-body-large;
    font-weight: 700;
}

@mixin kol-identity {
    /* kol/identity */
    font-family: var(--font-family), sans-serif;
    font-weight: 700;
    font-size: var(--font-size-font-size-4);
    line-height: var(--line-height-text);
    color: var(--color-dominant-on-dominant)
}


@mixin kol-small-identity {
    @include kol-identity;
    /* kol/small-identity */
    font-size: var(--font-size-font-size-3);
    line-height: var(--line-height-var-text);
    color: var(--color-dominant-on-dominant)
}

@mixin kol-username {
    /* kol/username */
    font-family: var(--font-family), sans-serif;
    font-weight: 400;
    font-size: var(--font-size-font-size-3);
    line-height: var(--line-height-text);
    color: var(--color-dominant-tertiary)
}

@mixin kol-small-username {
    /* kol/small-username */
    @include kol-username;
    font-size: var(--font-size-font-size-1);
    line-height: var(--line-height-var-text);
}